import { Component, effect, inject, OnDestroy, OnInit, untracked } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PollsContainerComponent } from '../../components/polls-container/polls-container.component';
import { MainContentDirective } from '../../directives/main-content.directive';
import { HeaderComponent } from '../../components/header/header.component';
import { SetSelectedSchoolYear } from '../../../core/store/school-year-selector/school-year-selector.actions';
import { parseInt } from 'lodash';
import { getMergedParamsFromRouteSnapshot, select } from '../../../core/functions';
import { SchoolYearSelectorState } from '../../../core/store/school-year-selector/school-year-selector.state';
import { DictionaryState } from '../../../core/store/dictionary/dictionary.state';

@Component({
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    standalone: true,
    imports: [HeaderComponent, MainContentDirective, PollsContainerComponent, RouterOutlet],
})
export class MainPageComponent implements OnDestroy, OnInit {
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _store = inject(Store);

    year = select(SchoolYearSelectorState.selectedSchoolYear);
    minimal = select(SchoolYearSelectorState.minimalSchoolYear);

    constructor() {
        effect(() => {
            if (this.year()) {
                if (this.minimal() && this.year().id < this.minimal()) {
                    const currentYear = this._store.selectSnapshot(DictionaryState.currentSchoolYear);

                    untracked(() => {
                        this._store.dispatch(new SetSelectedSchoolYear({ id: currentYear?.id }));
                        this._router.navigate(['/'], { replaceUrl: true });
                    });
                }
            }
        });
    }
    public ngOnInit() {
        this._resolveSelectedYear();
    }

    public ngOnDestroy(): void {}

    private _resolveSelectedYear(): void {
        const schoolYearFromParams = parseInt(getMergedParamsFromRouteSnapshot(this._route.snapshot.root)?.selectedSchoolYear);

        const selectedYearId = this._store.selectSnapshot(SchoolYearSelectorState.selectedSchoolYearId);
        const currentYear = this._store.selectSnapshot(DictionaryState.currentSchoolYear);

        this._store.dispatch(new SetSelectedSchoolYear({ id: schoolYearFromParams || selectedYearId || currentYear?.id }));
    }
}
